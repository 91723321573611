/*
  Collapses/expands an element with a height transition (like an accordion).

  To use, ensure the element has the following styles as an example:

  .element {
    overflow: hidden;
    transition: height 0.5s ease-in-out;
    height: 0;
  }
*/

export const collapseElement = (element) => {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = '';

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(() => {
    element.style.height = `${sectionHeight}px`;
    element.style.transition = elementTransition;

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(() => {
      element.style.height = `${0}px`;
    });
  });

  // mark the section as "currently collapsed"
  element.setAttribute('data-collapsed', 'true');
  element.removeAttribute('data-expanded');
};

export const expandElement = (element) => {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // have the element transition to the height of its inner content
  element.style.height = `${sectionHeight}px`;

  // when the next css transition finishes (which should be the one we just triggered)
  element.addEventListener('transitionend', () => {
    // remove "height" from the element's inline styles, so it can return to its initial value
    element.style.height = 'auto';
  }, { once: true });

  // mark the section as "currently not collapsed"
  element.setAttribute('data-expanded', 'true');
  element.removeAttribute('data-collapsed');
};
